import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';
import { INSURANCE_CONTRACT_STATUS } from 'qonto/constants/insurance-hub';

export const getEmptyStateConfig = (intl, { ctaCallback, isGermanOrganization }) => {
  let dummyData = getDummyData(intl);

  let subtitle = isGermanOrganization
    ? intl.t('insurance-hub.policies.empty-state.a3.de-orga.subtitle')
    : intl.t('insurance-hub.policies.empty-state.a3.fr-orga.subtitle');

  return {
    name: 'insurance-hub',
    featureName: 'insurance',
    permissionKey: 'insuranceContracts',
    variations: {
      ES_A3: [
        {
          output: {
            code: CODES.ES_A3,
            layout: LAYOUT.DISCOVER_PREVIEW,
            dummyData,
            badgeType: BADGE_TYPE.ACTIVATE,
            badgeText: intl.t('empty-states.system.activate.a3.badge'),
            title: intl.t('insurance-hub.policies.empty-state.a3.title'),
            subtitle,
            ctaText: intl.t('insurance-hub.policies.empty-state.a3.cta'),
            onClick: ctaCallback,
          },
        },
      ],
    },
  };
};

export const getDummyData = intl => {
  let renewalDate = dayjs().add(1, 'year').format('YYYY-MM-DD');

  return {
    items: [
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-1.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-1.policy-number'),
        price: {
          value: 15,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.ACTIVE,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-2.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-2.policy-number'),
        price: {
          value: 16,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.ACTIVE,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-3.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-3.policy-number'),
        price: {
          value: 34,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.PENDING_OTHERS,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-4.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-4.policy-number'),
        price: {
          value: 12,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT,
      },
    ],
  };
};
