export const DEFAULT_SOURCE_AMOUNT = '100.00';

export const DEFAULT_SOURCE_CURRENCY_CODE = 'EUR';
export const DEFAULT_TARGET_CURRENCY_CODE = 'USD';

export const ERROR_CODE = {
  ABOVE_MAXIMUM_AMOUNT: 'above_max_amount',
  BELOW_MINIMUM_AMOUNT: 'below_min_amount',
  NO_PAYMENT_OPTION_AVAILABLE: 'no_payment_option_available',
  TARGET_AMOUNT_VOLATILE: 'target_amount_volatile',
};

export const PAY_IN = {
  LOCAL: 'BANK_TRANSFER',
};

export const PAY_OUT = {
  INTERAC: 'INTERAC',
  LOCAL: 'BANK_TRANSFER',
  OUR: 'SWIFT_OUR',
  SHA: 'SWIFT',
};

export const QUOTE_CREATION_STATUS = {
  SUCCESS: 'success',
  UNEXPECTED_ERROR: 'unexpected-error',
  VALIDATION_ERROR: 'validation-error',
};

export const RATE_TYPE = {
  FIXED: 'FIXED',
  FLOATING: 'FLOATING',
};

export const STATUSES = {
  PENDING: 'PENDING',
};

export const TYPE = {
  SOURCE: 'SOURCE',
  TARGET: 'TARGET',
};
