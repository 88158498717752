export const PRICE_PLAN_LINEUPS = {
  SOLO: 'solo',
  TEAMS: 'teams',
};

export const PRICE_PLANT_TRAITS = {
  active: 'active',
  free_trial: 'free_trial',
  recommended: 'recommended',
  popular: 'popular',
};

// This is the list of all price plan features available as of 2024-10-06
export const PRICE_PLAN_FEATURES = [
  'accountant_access',
  'additional_users',
  'advanced_dashboard',
  'advertising_card',
  'attachment_collection',
  'bookkeeping',
  'card_restrictive_categories',
  'checks',
  'connect',
  'connect_integration_level_bronze',
  'connect_integration_level_gold',
  'connect_integration_level_platinum',
  'connect_integration_level_silver',
  'connect_premium',
  'custom_export_templates',
  'custom_labels',
  'direct_debits',
  'direct_debits_collection',
  'f24',
  'flash_card',
  'fx_transfers',
  'manager_role',
  'multi_accounts',
  'multi_transfers',
  'pagopa',
  'pay_later',
  'physical_card',
  'probative_value',
  'quotes',
  'receipt_reminder',
  'request_reimbursement_expense',
  'request_reimbursement_mileage',
  'request_transfer',
  'request_card',
  'tasks',
  'receivable_invoices',
  'receivable_invoices_v2',
  'recurring_invoices',
  'referral_program',
  'requests',
  'requests_v3',
  'savings',
  'sepa_transfers',
  'supplier_invoices',
  'suppliers_management',
  'team_budget',
  'team_management',
  'transactions',
  'transactions_review',
  'vat_autodetection',
  'vat_input',
  'virtual_card',
  'card_advanced_settings',
  'advanced_manager_permissions',
];
