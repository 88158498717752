export const ENABLED_LEGAL_COUNTRIES = ['DE', 'FR'];

export const PROVIDERS_MAP = {
  DE: 'Signal Iduna',
  FR: 'Stello',
};

export const OFFER_ROUTE = 'insurance-hub.offer';

export const INSURANCE_CONTRACT_STATUS = {
  ACTIVE: 'active',
  PENDING_PAYMENT: 'pending_payment',
  PENDING_OTHERS: 'pending_others',
  ACTION_REQUIRED: 'action_required',
  EXPIRED: 'expired',
};

export const INSURANCE_CONTRACT_STATUS_ORDER = [
  INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED,
  INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT,
  INSURANCE_CONTRACT_STATUS.ACTIVE,
  INSURANCE_CONTRACT_STATUS.PENDING_OTHERS,
  INSURANCE_CONTRACT_STATUS.EXPIRED,
];

export const INSURANCE_CONTRACT_FREQUENCY = {
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'annual',
};
